<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
            type="button"
            @click="goBack()"
            class="btn btn-inverse"
            title="Kembali"
          >
            Kembali
          </button>
        </span>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">{{ $t("document_date") }}</small>
            <h6>
              {{
                !getValue(detail.document_date)
                  ? ""
                  : formatDate(detail.document_date)
              }}
            </h6>
            <small class="text-muted">{{ $t("from") }}</small>
            <h6>{{ getValue(detail.unit_sender, "name") }}</h6>
            <small class="text-muted">{{ $t("subject") }}</small>
            <h6>{{ getValue(detail.subject) }}</h6>
            <small class="text-muted">{{ $t("agenda_number") }}</small>
            <h6>{{ getValue(detail.agenda_number) }}</h6>
            <small class="text-muted" v-if="detail.minister_approval">{{
              $t("minister_approval")
            }}</small>
            <h6 v-if="detail.minister_approval">{{ detail.status }}</h6>
            <small class="text-muted"
              >{{ $t("document_type") }} / {{ $t("document_name") }}</small
            >
            <h6>
              {{ getValue(detail.category, "name") }} /
              {{ getValue(detail.status, "name") }}
            </h6>
            <small class="text-muted">{{ $t("document_urgency") }}</small>
            <h6>{{ getValue(detail.urgency, "name") }}</h6>
            <small class="text-muted">{{ $t("document_speed") }}</small>
            <h6>{{ getValue(detail.document_speed, "name") }}</h6>
          </div>

          <div class="col-md-6 pull-left">
            <small class="text-muted">{{ $t("document_summary") }}</small>
            <h6>
              <span v-if="detail.description === ''">-</span>
              <span v-else v-html="detail.description"></span>
            </h6>
            <small class="text-muted">{{ $t("document_number") }}</small>
            <h6>
              {{
                detail.document_number === "-" ||
                detail.document_number === null ||
                detail.document_number === undefined
                  ? "-"
                  : detail.document_number
              }}
            </h6>
            <small class="text-muted">{{ $t("status") }}</small>
            <h6>{{ $t(getValue(detail.approval, "name")) }}</h6>
            <small class="text-muted"
              >{{ $t("page") }}/{{ $t("attachment") }}</small
            >
            <h6>
              {{ getValue(detail.pages) }} /
              {{
                detail.attachment_total === "" ||
                detail.attachment_total === undefined
                  ? "-"
                  : detail.attachment_total +
                    " " +
                    getValue(detail.attachment, "name")
              }}
            </h6>
            <small class="text-muted">{{ $t("to") }}</small>
            <h6 v-if="!receivers.length">-</h6>
            <h6 v-else>
              <table>
                <tr v-for="(rec, index) in receivers" :key="index">
                  <td>{{ index + 1 + ". " + rec.name }}</td>
                </tr>
              </table>
            </h6>
            <small class="text-muted">{{ $t("cc") }}</small>
            <h6 v-if="!carbons.length">-</h6>
            <h6 v-else>
              <table>
                <tr v-for="(rec, index) in carbons" :key="index">
                  <td>{{ index + 1 + ". " + rec.name }}</td>
                </tr>
              </table>
            </h6>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-12 pull-left">
            <small class="text-muted">{{ $t("attachments") }}</small>
            <br />
            <h6 v-if="!containAttachment">-</h6>
            <table v-else class="table table-stripped">
              <tr>
                <th>{{ $t("file") }}</th>
                <th>{{ $t("sender") }}</th>
                <th>{{ $t("date") }}</th>
                <th>Buka File</th>
              </tr>
              <tr
                v-for="file in state.detail.attachments"
                v-bind:key="file.filename"
              >
                <td>
                  {{ file.name }}
                </td>
                <td>
                  {{ state.detail.unit_sender.name }}
                </td>
                <td>
                  {{ formatDateTime(file.created_at) }}
                </td>
                <td>
                  <!-- <a
                    class="btn btn-success text-white"
                    @click="downloadFileForce(file.url)"
                    title="Download File"
                    ><i class="fa fa-download text-white"></i>
                  </a> -->
                  <a
                    v-if="file.filename.includes('.pdf')"
                    @click="prev(file.uuid)"
                    class="btn btn-info text-white"
                    title="View File"
                    ><i class="fa fa-eye text-white"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <hr v-if="containFile" />
      <div class="row" v-if="containFile">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-12 pull-left">
            <small class="text-muted">{{ $t("drafting_result") }}</small>
            <br />
            <h6 v-if="!containFile">-</h6>
            <table v-else class="table table-stripped">
              <tr>
                <th>{{ $t("file") }}</th>
                <th>{{ $t("sender") }}</th>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("action") }}</th>
              </tr>
              <tr v-for="file in state.detail.files" v-bind:key="file.filename">
                <td>
                  {{ file.filename }}
                </td>
                <td>
                  {{ state.detail.unit_sender.name }}
                </td>
                <td>
                  {{ formatDateTime(file.created_at) }}
                </td>
                <td>
                  <!-- <a
                    class="btn btn-success text-white"
                    @click="downloadFileForce(file.url)"
                    title="Download File"
                    ><i class="fa fa-download text-white"></i>
                  </a> -->
                  <a
                    v-if="file.filename.includes('.pdf')"
                    @click="prevDoc(file.uuid)"
                    class="btn btn-info text-white"
                    title="View File"
                    ><i class="fa fa-eye text-white"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <hr style="margin: 0px" />
      <!-- <div class="row">
        <div class="col-md-12">
          <small class="text-muted m-b-10">Catatan Perubahan</small>
          <template v-for="(log, index) in detail.logs">
            <div :key="index" class="m-t-10">
              <div
                style="padding:10px;background-color:#E4F3F9;border-radius:5px;margin-bottom:10px;"
              >
                <h6>
                  {{ index + 1 }}. {{ log.user.name }} (<b>{{
                    log.unit.name
                  }}</b
                  >)
                </h6>
                <h6><b>Isi Surat :</b></h6>
                <h6><span v-html="log.content"></span></h6>
              </div>
              <div
                style="padding:10px;background-color:#ddd;border-radius:5px;"
              >
                <h6><b>Catatan :</b></h6>
                <h6><span v-html="log.note"></span></h6>
              </div>
            </div>
          </template>
        </div>
      </div> -->
      <div class="pt-2 mx-auto left-on-mobile">
        <button
          class="btn btn-warning font-bold"
          @click="$store.dispatch('drafting_file_declined/onEdit', detail)"
        >
          Edit <i class="fa font-bold fa-edit"></i>
        </button>
      </div>
      <ul class="timeline" style="margin: 0px">
        <template v-for="(log, index) in detail.logs">
          <li
            :class="[{ 'timeline-inverted': index % 2 == 1 }, 'timeline-item']"
            :key="index"
          >
            <div class="timeline-badge success">
              <avatar :username="log.user.name"></avatar>
            </div>
            <div class="timeline-panel">
              <div class="timeline-body">
                <span class="sl-date">{{
                  formatDateTime(log.created_at)
                }}</span>
                <span
                  v-if="log.status == 'Disetujui dan Ditandatangani'"
                  class="badge badge-success text-white font-bold ml-1"
                  >Disetujui dan Ditandatangani</span
                >
                <span
                  v-else-if="log.status == 'Menunggu Persetujuan'"
                  class="badge badge-warning text-white font-bold ml-1"
                  >Menunggu Persetujuan</span
                >
                <span
                  v-else-if="log.status == 'Revisi'"
                  class="badge badge-danger text-white font-bold ml-1"
                  >Revisi</span
                >
                <span
                  v-else-if="log.status == 'Tolak'"
                  class="badge badge-danger text-white font-bold ml-1"
                  >Tolak</span
                >
                <span v-else class="badge badge-info text-white font-bold ml-1"
                  >Simpan Draft</span
                >
                <br /><a href="javascript:void(0)" class="link font-weight-bold"
                  >{{ log.user.name }}
                </a>
                <span class="h6 text-secondary">({{ log.unit.name }})</span>
                <p v-if="log.status == 'Disetujui dan Ditandatangani'">
                  melakukan persetujuan dan tanda tangan surat.
                </p>
                <p v-else-if="log.status == 'Menunggu Persetujuan'">
                  mengajukan persetujuan surat ke atasan.
                </p>
                <p v-else-if="log.status == 'Revisi'">
                  mengajukan revisi surat.
                </p>
                <p v-else-if="log.status == 'Tolak'">
                  melakukan penolak surat.
                </p>
                <p v-else>melakukan simpan draft surat.</p>
                <p>Catatan : <span v-html="log.note"></span></p>
                <template v-if="log.file">
                  <a
                    :href="log.link_file"
                    target="_blank"
                    class="link text-info"
                    >{{ log.filename }}</a
                  >
                  <div class="like-comm mt-2">
                    <a
                      :href="log.link_file"
                      target="_blank"
                      class="btn btn-outline-success pt-0 pb-0 mr-2"
                      ><i class="fa fa-download"></i> Download</a
                    >
                  </div>
                </template>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <hr />
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
export default {
  components: {
    RotateSquare5,
  },
  computed: {
    containFile() {
      return this.detail.files !== undefined && this.detail.files.length;
    },
    containAttachment() {
      return (
        this.detail.attachments !== undefined && this.detail.attachments.length
      );
    },
    detail() {
      return this.$store.state.drafting_file_declined.detail;
    },
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.drafting_file_declined;
    },
    receivers() {
      let r = [];
      let r2 = [];
      if (this.detail.receivers !== undefined) {
        if (this.detail.receivers.length) {
          r = this.detail.receivers.filter(
            (er) => er.pivot.labeling == 1 && er.pivot.flag == "sb"
          );
        }
      }
      if (this.detail.receivers_label !== undefined) {
        if (this.detail.receivers_label.length) {
          r2 = this.detail.receivers_label.filter(
            (er) => er.pivot.is_labeling == 1 && er.pivot.flag == "sb"
          );
        }
      }
      return r.concat(r2);
    },
    carbons() {
      let r = [];
      let r2 = [];
      if (this.detail.receivers !== undefined) {
        if (this.detail.receivers.length) {
          r = this.detail.receivers.filter(
            (er) => er.pivot.labeling == 0 && er.pivot.flag == "cc"
          );
        }
      }
      if (this.detail.receivers_label !== undefined) {
        if (this.detail.receivers_label.length) {
          r2 = this.detail.receivers_label.filter(
            (er) => er.pivot.is_labeling == 0 && er.pivot.flag == "cc"
          );
        }
      }
      return r.concat(r2);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "drafting",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    prevDoc(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_out",
          id: this.detail.document.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getValue(data, key) {
      if (key !== undefined) {
        if (data !== undefined || data !== null || data !== "") {
          return data[key];
        }
      } else {
        return data;
      }
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("drafting_file_declined/STATE", state);
      this.$store.dispatch(
        "drafting_file_declined/getdraftingById",
        this.$route.params
      );
    },
    goBack() {
      this.$store.dispatch("drafting_file_declined/onCancel");
    },
  },
};
</script>
<style>
@media (max-width: 767px) {
  .left-on-mobile {
    margin: 0px 15px !important;
  }
}
</style>